import React from 'react';
import { Datagrid, EditButton, DeleteButton, List, TextField, NumberField } from 'react-admin';

const StoryList = (props: any) => (
    <List {...props} perPage={25} bulkActionButtons={false} sort={{ field: 'order', order: 'ASC' }}>
        <Datagrid>
            <NumberField source="order" />
            <TextField source="title" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default StoryList;
