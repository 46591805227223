import React, { FC, useState, useEffect } from 'react';
import {
    Datagrid,
    Create,
    EditButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    TextField,
    TextInput,
    DateInput,
    DateTimeInput,
    useTranslate,
    SaveButton,
    Toolbar,
    useDataProvider,
    Loading,
    Error
} from 'react-admin';

import { FieldProps, Category } from '../types';

const CreateTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.groups.name', { smart_count: 1 })}
        </span>
    ) : null;
};

const GroupEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const StoryDates = () => {
    const dataProvider = useDataProvider();
    const [stories, setStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    useEffect(() => {
        dataProvider.getList('stories',{
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'order', order: 'ASC' },
            filter: {},
        })
            .then(({ data }) => {
                // @ts-ignore
                setStories(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            })
    }, []);

    if (loading) return <Loading />;
    if (error) { // @ts-ignore
        return null
    }
    if (!stories) return null;

    const storyFields = stories.map((story) => {
        // @ts-ignore
        return  <DateTimeInput label={"Story " + story.title} source={'stories.' + story.id}/>
    });

        return (<>
                {storyFields}
            </>
        );
};

const GroupCreate = (props: any) => {
    const random = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);
    const url = window.location.protocol + '//' + window.location.host + "/login/" + random;

    const transform = data => ({
        ...data,
        url: url,
        code: random,
    });

    return (
    <Create title={<CreateTitle />} transform={transform} {...props}>
        <SimpleForm toolbar={<GroupEditToolbar />}>
            <TextInput label="Naam" source="name" />
            <DateTimeInput label="Vervaldatum" source="date" />
            <StoryDates />
        </SimpleForm>
    </Create>
)};

export default GroupCreate;
