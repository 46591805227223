import { TranslationMessages } from 'ra-core';
import englishMessages from 'ra-language-english';

const customEnglishMessages: TranslationMessages = {
    ...englishMessages,
    resources: {
       groups: {
           name: "Groepen",
       },
        stories: {
           name: "Stories"
       }
    },
};

export default customEnglishMessages;
