import React, { FC } from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    ImageInput,
    ImageField,
    FileInput,
    FileField,
    TextInput,
    NumberInput,
    useTranslate,
    ReferenceArrayInput,
    SelectArrayInput,
    ArrayInput,
    SimpleFormIterator,
    Toolbar,
    SaveButton,
    useRedirect,
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';

import { FieldProps, Category } from '../types';

const FileTitle: FC<FieldProps<Category>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.stories.name', { smart_count: 1 })} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

const FileEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const StoryEdit = (props: any) => {
    const redirect = useRedirect();

    const onSuccess = ({ data }) => {
        redirect('/groups/' + data.groupId);
    };
    return (
        <Edit onSuccess={onSuccess} undoable={false} title={<FileTitle />} {...props}>
            <SimpleForm toolbar={<FileEditToolbar />} redirect="edit">
                <NumberInput label="Volgorde" source="order" />
                <TextInput label="Titel NL" source="title" />
                <TextInput label="Titel EN" source="title_en" />
                <RichTextInput label="Story NL" source="description" />
                <RichTextInput label="Story EN" source="description_en" />
                <ArrayInput source="images">
                    <SimpleFormIterator>
                        <TextInput label="Afbeelding titel NL" source="title_nl" />
                        <ImageInput source="images" label="Afbeelding NL" accept="image/*">
                            <ImageField source="image_en" title="image_nl" />
                        </ImageInput>
                        <TextInput label="Afbeelding beschrijving NL" source="description_nl" />
                        <TextInput label="Afbeelding titel EN" source="title_en" />
                        <ImageInput source="images_en" label="Afbeelding EN" accept="image/*">
                            <ImageField source="image_en" title="image_en" />
                        </ImageInput>
                        <TextInput label="Afbeelding beschrijving EN" source="description_en" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput source="videos">
                    <SimpleFormIterator>
                        <TextInput label="Video titel NL" source="videoTitle_nl" />
                        <TextInput type="url" label="Vimeo-link NL" source="vimeoUrl_nl" />
                        <RichTextInput label="Video beschrijving NL" source="description_nl" />
                        <TextInput label="Video titel EN" source="videoTitle_en" />
                        <TextInput type="url" label="Vimeo-link EN" source="vimeoUrl_en" />
                        <RichTextInput label="Video beschrijving EN" source="description_en" />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    )
};

export default StoryEdit;
