import BookIcon from '@material-ui/icons/Book';

import StoryCreate from './StoryCreate';
import StoryList from './StoryList';
import StoryEdit from './StoryEdit';

export default {
    create: StoryCreate,
    list: StoryList,
    edit: StoryEdit,
    icon: BookIcon,
};
