// @ts-nocheck

import React, {cloneElement, FC, useEffect, useState} from 'react';
import {
    Datagrid,
    Edit,
    EditButton,
    Button,
    NumberField,
    ReferenceManyField,
    SimpleForm,
    TextInput,
    useTranslate,
    SaveButton,
    Toolbar, useDataProvider,
    DateInput,
    DateTimeInput,
    Loading,
    ResourceContextProvider,
    useResourceContext,
    CreateContextProvider,
    Resource,
    useRefresh
} from 'react-admin';

import { connect } from "react-redux";
import { push } from "react-router-redux";

import { Drawer, TableRow } from '@material-ui/core';
import StoryCreate from '../CustomStories/StoryCreate';

import { FieldProps, Category } from '../types';
import {Route} from "react-router";

const GroupsTitle: FC<FieldProps<Group>> = ({ record }) => {
    const translate = useTranslate();
    return record ? (
        <span>
            {translate('resources.groups.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

const GroupEditToolbar = (props: any) => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);

const StoryDates = ({groupId, props, record}) => {
    const dataProvider = useDataProvider();
    const [stories, setStories] = useState([]);
    const [customStories, setCustomStories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [copyStory, setCopyStory] = useState("z");
    const [error, setError] = useState();

    const refresh = useRefresh();
    const removeStory = (storyId) => {
        dataProvider.delete('custom_stories', {id: storyId}).then(() => {
            refresh();
        });
    };

    const updateDates = (custom, storyDates, groupId) => {
        console.log(groupId)
        if(custom) {
            dataProvider.update('groups', {id: groupId, data: {custom_stories: storyDates}}).then(() => {
                refresh();
            });
        } else {
            dataProvider.update('groups', {id: groupId, data: {stories: storyDates}}).then(() => {
                refresh();
            });
        }
    };

    const handleClose = () => {
        // @ts-ignore
        props.push("/groups/" + props.id);
    };

    useEffect(() => {
        dataProvider.getList('stories',{
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'order', order: 'ASC' },
            filter: {},
        })
            .then(({ data }) => {
                setStories(data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
            });

        dataProvider.getList('custom_stories',{
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'order', order: 'ASC' },
            filter: {groupId: groupId},
        })
        // @ts-ignore
            .then(( custom ) => {
                // @ts-ignore
                setCustomStories(custom.data);
                setLoading(false);
            })
            .catch(error => {
                setError(error);
                setLoading(false);
            });

    }, []);
    if (loading) return <Loading />;
    if (error) { // @ts-ignore
        return null
    }
    if (!stories) return null;

    const storyFields = stories.map((story) => {
        // @ts-ignore
        return  (
            <TableRow>
            <DateTimeInput label={"Story - " + story.title} source={'stories.' + story.id}/>
                <Button onClick={() => {props.push("/groups/" + props.id + "/customcreate"); story.id = null; setCopyStory(story)}}  label={"dupliceren en bewerken"}/>
                {(record.stories && story.id in record.stories) ? (<Button onClick={() => {delete record.stories[story.id]; updateDates(false, record.stories, props.id)}} label={"datum verwijderen"}/>) : null}
            </TableRow>
    )
    });

    const customStoryFields = customStories.map((story) => {
        // @ts-ignore
        return  (
            <TableRow>
                <DateTimeInput label={"Custom story - " + story.title} source={'custom_stories.' + story.id}/>
                <Button  onClick={() => {props.push("/custom_stories/" + story.id)}} label={"bewerken"}/>
                <Button onClick={() => {removeStory(story.id)}} label={"verwijderen"}/>
                {(record.custom_stories && story.id in record.custom_stories) ? (<Button onClick={() => {delete record.custom_stories[story.id]; updateDates(true, record.custom_stories, props.id)}} label={"datum verwijderen"}/>) : null}
            </TableRow>
        )
    });

    return (<>
            <hr />
            <h4>Standaard Stories</h4>
            {storyFields}
            <hr />
            <h4>Custom Stories</h4>
            {customStoryFields}
            <Route
                path={`/groups/${props.id}/customcreate`}
                render={() => (
                    <Drawer open onClose={() => handleClose()}>
                        <StoryCreate basePath={"custom_stories"} initial={copyStory} resource={"custom_stories"} onClose={() => handleClose()} onSave={() => { handleClose(); refresh(); }} groupId={props.id}/>
                    </Drawer>
                )}
            />
        </>
    );
};

function useCreateController(props: any) {

}

const GroupEdit = (props: any) => {
    return (
        <Edit undoable={false} title={<GroupsTitle />} {...props}>
            <SimpleForm toolbar={<GroupEditToolbar />} redirect="edit">
                <TextInput label="Naam" source="name" />
                <TextInput fullWidth={true} disabled label="URL" source="url"/>
                <DateTimeInput label="Vervaldatum" source="date" />
                <StoryDates groupId={props.id} props={props} />
                <Button onClick={() => props.push("/groups/" + props.id + "/customcreate")} label={"Custom story toevoegen"} />
            </SimpleForm>
        </Edit>
    )
};


export default connect(
    undefined,
    { push }
)(GroupEdit);
