import React, {Component} from 'react';
import {
    withRouter
} from "react-router-dom";
import Draggable from 'react-draggable';
import './story.scss';
import firebase from "firebase";
import parse from 'html-react-parser';
import Taskbar from "./taskbar";
import $ from "jquery";
import _ from 'underscore';
import ImageGallery from 'react-image-gallery';
import { withTranslation } from 'react-i18next';

class Story extends Component {
    constructor(props) {
        super(props);
        this.storyRef = React.createRef(null);
        this.imageRef = React.createRef(null);
        this.videoRef = React.createRef(null);


        this.state = {
            stories: [],
            currentStory: null,
            selected: "story",
        }
    }

    componentDidMount() {
        const db = firebase.firestore();

        const groupId = localStorage.getItem('groupId');
        if (groupId) {
            db.collection("groups").doc(groupId)
                .get()
                .then((doc) => {
                    if(!doc.exists) {
                        this.logOut()
                    } else {
                        const group = doc.data();
                        if (group) {
                            const expired = group.date && group.date.seconds && group.date.seconds < new Date().getTime() / 1000;

                            if(expired) {
                                this.logOut();
                            } else {
                                this.fetchStories(group.stories, group.custom_stories)
                            }
                        }
                    }
                })
                .catch(function(error) {
                    console.log("Error getting documents: ", error);
                });
        } else {
            this.props.history.push('/login/empty')
        }
    }

    hide(id) {
        let x = document.querySelector(id);
        if(x && x.style) {
            if (x.style.visibility === "hidden") {
                x.style.visibility = "visible";
            } else {
                x.style.visibility = "hidden";
            }
        }
    }

    closeLastStory() {
        this.setState({story: null});
        $("#start-menu").toggle('hide')
    }

    openStory(id) {
        this.setState({currentStory: null});
        this.setState({currentStory: id});
    }

    async fetchStories(storyIds, customIds) {
        const db = firebase.firestore();
        const storyRef = db.collection("stories");
        const customStoryRef = db.collection("custom_stories");

        const stories = [];
        const customStories = [];

        if(storyIds && Object.keys(storyIds).length > 0) {
            await storyRef.where(firebase.firestore.FieldPath.documentId(), "in", Object.keys(storyIds)).get().then((querySnapshot) => {
                if(querySnapshot.empty) {
                    console.log("empty")
                } else {
                    querySnapshot.forEach((doc) => {
                        stories.push({...doc.data(), id: doc.id, date: storyIds[doc.id]})
                    });
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
        }

        if (customIds && Object.keys(customIds).length > 0) {
            await customStoryRef.where(firebase.firestore.FieldPath.documentId(), "in", Object.keys(customIds)).get().then((querySnapshot) => {
                if(querySnapshot.empty) {
                    console.log("empty")
                } else {
                    querySnapshot.forEach((doc) => {
                        customStories.push({...doc.data(), id: doc.id, date: customIds[doc.id]})
                    });
                }
            }).catch(function (error) {
                console.log("Error getting document:", error);
            });
        }

        const sortedStories = _.sortBy(stories.concat(customStories), 'date');
        this.setState({stories: sortedStories});

        for (let i = sortedStories.length - 1; i >= 0; i--) {
            const story = sortedStories[i]
            const available = story.date && story.date.seconds && story.date.seconds < new Date().getTime() / 1000;
            if(available) {
                //this.setState({currentStory: story.id});
                break;
            }
        }
    }

    handleDrag(window) {
        this.setState({selected: window})
    }

    renderImages(storyImg) {
        const {selected} = this.state;
        const { t, i18n } = this.props;


        if(storyImg.length > 0) {
            const images = storyImg.map((image, index) => {
                const url = (i18n.language === "en-US" ? (image.image_en ? image.image_en : null) : (image.image_nl ? image.image_nl : ""));
                const title = (i18n.language === "en-US" ? (image.title_en ? image.title_en : null) : (image.title_nl ? image.title_nl : ""));
                const description = (i18n.language === "en-US" ? (image.description_en ? image.description_en : null) : (image.description_nl ? image.description_nl : ""));

                return (
                    <Draggable
                        nodeRef={this.imageRef.current}
                        axis="both"
                        handle=".imageHeader"
                        bounds=".storyPage"
                        key={image.url}
                        onStart={() => this.handleDrag(`images${index}`)}
                        defaultPosition={{x: 0, y: 0}}>
                        <div className="imageBox" id={`image${index}`} style={selected === `images${index}` ? {zIndex: 5} : {zIndex: 4}}>
                            <div className="imageHeader">
                                <h3>{title}</h3>
                                <img onClick={() => {
                                    this.hide(`#image${index}`);
                                }} className="closeBtn" src="../close.png"/>
                            </div>
                            <div className="imageBody">
                                <ImageGallery showThumbnails={false} showPlayButton={false} showNav={false} items={[{original: url.src}]}/>
                            </div>
                            <div className={"imageText"}>
                                {parse(description)}
                            </div>
                        </div>
                    </Draggable>
                )
            });

            return images;
        }
    }

    renderVideo(storyVideos) {
        const {selected} = this.state;
        const { t, i18n } = this.props;

        if(storyVideos.length > 0) {
            const videos = storyVideos.map((video, index) => {
                const url = (i18n.language === "en-US" ? (video.vimeoUrl_en ? video.vimeoUrl_en : null) : (video.vimeoUrl_nl ? video.vimeoUrl_nl : ""));
                const title = (i18n.language === "en-US" ? (video.videoTitle_en ? video.videoTitle_en : null) : (video.videoTitle_nl ? video.videoTitle_nl : ""));
                const description = (i18n.language === "en-US" ? (video.description_en ? video.description_en : null) : (video.description_nl ? video.description_nl : ""));

                return (
                    <Draggable
                        nodeRef={this.videoRef.current}
                        axis="both"
                        handle=".videoHeader"
                        bounds=".storyPage"
                        key={url}
                        onStart={() => this.handleDrag(`video${index}`)}
                        defaultPosition={{x: 0, y: 0}}>
                        <div className="videoBox" id={`video${index}`} style={selected === `video${index}` ? {zIndex: 5} : {zIndex: 4}}>
                            <div className="videoHeader">
                                <h3>{title}</h3>
                                <img onClick={() => {
                                    this.hide(`#video${index}`);
                                }} className="closeBtn" src="../close.png"/>
                            </div>
                            <div className="videoBody">
                                <iframe frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen
                                        src={url}/>
                            </div>
                            <div className={"videoText"}>
                                {parse(description)}
                            </div>
                        </div>
                    </Draggable>)
            });

            return videos;
        };
    }

    renderText(title, description, id) {
        const {selected} = this.state;

        if(description) {
            return (
                <Draggable
                    nodeRef={this.storyRef.current}
                    axis="both"
                    bounds=".storyPage"
                    handle=".storyHeader"
                    key={id}
                    onStart={() => this.handleDrag("story")}
                    defaultPosition={{x: 0, y: 0}}>
                    <div ref={this.storyRef.current} style={selected === "story" ? {zIndex: 5} : {zIndex: 4}} className="storyBox">
                        <div className="storyHeader">
                            <h3>{title}</h3>
                            <img onClick={() => {
                                this.hide(".storyBox");
                            }} className="closeBtn" src="../close.png"/>
                        </div>
                        <div className="storyBody">
                            {parse(description)}
                        </div>
                    </div>
                </Draggable>
            )
        }
    }

    logOut() {
        localStorage.removeItem('currentPage');
        localStorage.removeItem("groupId");
        localStorage.removeItem("groupName");

        this.props.history.push('/login/empty')
    }

    render() {
        const { t, i18n } = this.props;
        const {stories, currentStory} = this.state;
        const story = _.findWhere(stories, {id: currentStory});
        const storyImg = (story ? (i18n.language === "en-US" ? (story.images_en ? story.images_en : []) : (story.images ? story.images : [])) : []);
        const videos = (story ? (story.videos ? story.videos : []) : []);
        const title = (story ? (i18n.language === "en-US" ? (story.title_en ? story.title_en : null) : (story.title ? story.title : "")) : "");
        const description = (story ? (i18n.language === "en-US" ? (story.description_en && story.description_en.length > 0 ? story.description_en : null) : (story.description && story.description.length > 0 ? story.description : null)) : null);

        return (
            <div className="storyPage">
                {
                    this.renderText(title, description, currentStory)
                }
                {
                    this.renderImages(storyImg)
                }
                {
                    this.renderVideo(videos)
                }
                <Taskbar stories={stories} openStory={(id) => this.openStory(id)} currentStory={story} finished={false} description={description} images={storyImg} videos={videos}/>
            </div>
        )
    }

}
;

export default withRouter(withTranslation()(Story));
