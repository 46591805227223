import React, {Component} from 'react';
import Draggable from 'react-draggable';
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import './login.scss';

class Login extends Component {
    constructor(props) {
        super(props);
        this.loginRef = React.createRef(null);

        this.state = {
            wrong: false,
            code: null,
        }
    }
    componentDidMount () {
        const {code} = this.props.match.params;
        const db = firebase.firestore();

        db.collection("groups").where("code", "==", code)
            .get()
            .then((querySnapshot) => {
                if(querySnapshot.empty) {
                    localStorage.removeItem('groupId');
                    localStorage.removeItem('groupName');
                    this.setState({wrong: true})
                } else {
                    querySnapshot.forEach((doc) => {
                        const group = doc.data();

                        const expired = group.date && group.date.seconds && group.date.seconds < new Date().getTime() / 1000;
                        if(!expired) {
                            localStorage.setItem('code', code);
                            localStorage.setItem('groupId', doc.id);
                            localStorage.setItem('groupName', doc.data().name);
                            this.setState({code: code})
                        } else {
                            localStorage.removeItem('groupId');
                            localStorage.removeItem('groupName');
                            this.setState({code: "Verlopen"})
                        }
                    });
                }
            })
            .catch(function(error) {
                console.log("Error getting documents: ", error);
            });
    }

    redirectToStories = () => {
        const { history } = this.props;
        if(history) history.push(`/stories`)
    };

    checkCode = (event) => {
        event.preventDefault();
        this.redirectToStories();
    };

    render() {
        const { t, i18n } = this.props;
        const { code } = this.state;

        return(
            <div className="loginPage">
                    <div ref={this.loginRef.current} className="loginBox">
                        <div className="loginHeader">
                            <h3>{t('hunted')}</h3>
                        </div>
                        <p>Deze website is veilig en alleen toegankelijk met de speciaal verkregen inlogcode.
                            In de veilige omgeving die u na inloggen betreedt, vindt u belangrijke informatie
                            voor uw uiteindelijke missie.  </p>
                        <p><b>{t('login_with_password')}</b></p>
                        <form onSubmit={this.checkCode}>
                            <input value={code} name="password" style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} className="passwordInput"></input>
                            <button type="submit" className="loginBtn"><span>{t('login')}</span></button>
                        </form>
                    </div>
            </div>
        )
    }
};

export default withRouter(withTranslation()(Login));
