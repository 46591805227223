import React from 'react';
import { Datagrid, EditButton, DeleteButton, List, TextField } from 'react-admin';

const GroupList = (props: any) => (
    <List {...props} bulkActionButtons={false} sort={{ field: 'name', order: 'ASC' }}>
        <Datagrid>
            <TextField source="name" />
            <EditButton />
            <DeleteButton />
        </Datagrid>
    </List>
);

export default GroupList;
