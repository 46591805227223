import ProfileIcon from '@material-ui/icons/Person';

import GroupCreate from './GroupCreate';
import GroupList from './GroupList';
import GroupEdit from './GroupEdit';

export default {
    list: GroupList,
    create: GroupCreate,
    edit: GroupEdit,
    icon: ProfileIcon,
};
